<template>
    <div>
        <div class="text-caption pb-3 pt-1" :class="{ disabled: disableUnitSelection }">
            As LiveMine units are customisable, the Manufacturer unit must be mapped to an existing LiveMine unit.
        </div>
        <v-row>
            <v-col>
                <app-text-field v-model="manufacturerUnit" disabled label="Manufacturer Unit" />
            </v-col>

            <v-col cols="1" align="center" align-self="center" style="margin-bottom: 24px">
                <v-icon>mdi-arrow-right-bold</v-icon>
            </v-col>

            <v-col>
                <schema-search-select
                    v-model="mappedUnitId"
                    entity-key="unit"
                    label="LiveMine Unit"
                    search-when-blank
                    :disabled="disableUnitInput"
                    :rules="[(v) => !!v || 'Required']"
                />
            </v-col>
        </v-row>

        <!-- Optional Capacity Unit Section -->
        <div class="text-caption pb-3 pt-1" :class="{ disabled: disableUnitSelection }">
            This is an optional Capacity Unit or Item Weight Unit for tracking item stock levels in terms of weight and
            for tracking magazine capacities.
        </div>
        <v-row>
            <v-col>
                <app-text-field v-model="manufacturerCapacityUnit" disabled label="Manufacturer Capacity Unit" />
            </v-col>

            <v-col cols="1" align="center" align-self="center" style="margin-bottom: 24px">
                <v-icon>mdi-arrow-right-bold</v-icon>
            </v-col>

            <v-col>
                <schema-search-select
                    v-model="mappedCapacityUnitId"
                    entity-key="unit"
                    label="LiveMine Unit"
                    search-when-blank
                    :disabled="disableCapacityUnitInput"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "UnitMapping",
        props: {
            value: {
                type: [Object, Array],
                default: () => ({
                    mappedUnitId: null,
                    mappedCapacityUnitId: null,
                }),
            },
            item: {
                type: Object,
                default: null,
            },
            disableSelection: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            mappedUnitId: {
                get() {
                    return Array.isArray(this.value) && this.value.length > 0
                        ? this.value[0]?.mappedUnitId
                        : this.value.mappedUnitId;
                },
                set(unitId) {
                    this.updateUnit("mappedUnitId", unitId);
                },
            },
            mappedCapacityUnitId: {
                get() {
                    return Array.isArray(this.value) && this.value.length > 0
                        ? this.value[0]?.mappedCapacityUnitId
                        : this.value.mappedCapacityUnitId;
                },
                set(capacityUnitId) {
                    this.updateUnit("mappedCapacityUnitId", capacityUnitId);
                },
            },
            manufacturerUnit() {
                return this.item?.unitName || null;
            },
            manufacturerCapacityUnit() {
                return this.item?.capacityUnitName || null;
            },

            disableUnitSelection() {
                return this.disableSelection || this.item == null;
            },
            disableUnitInput() {
                if (this.item) return !this.item.unitName;
                return true;
            },
            disableCapacityUnitInput() {
                if (this.item) return !this.item.capacityUnitName;
                return true;
            },
        },

        watch: {
            item: {
                immediate: true,
                async handler(item, oldItem) {
                    if (!item) return;

                    if (
                        oldItem != null &&
                        (item.unitId === oldItem.unitId || item.capacityUnitId === oldItem.capacityUnitId)
                    ) {
                        return;
                    }

                    await this.lookupUnits(item);
                },
            },
        },

        methods: {
            updateUnit(key, value) {
                if (Array.isArray(this.value)) {
                    const updatedValues = this.value.map((item) => ({
                        ...item,
                        [key]: value,
                    }));
                    this.$emit("input", updatedValues);
                } else {
                    this.$emit("input", {
                        ...this.value,
                        [key]: value,
                    });
                }
            },
            async lookupUnits(item) {
                if (item.unitName) {
                    const { data: unitData } = await axios.get(`/api/MagazineCatalog/UnitExist/${item.unitName}`);
                    if (unitData != null) {
                        this.mappedUnitId = unitData;
                    }
                }

                if (item.capacityUnitName) {
                    const { data: capacityUnitData } = await axios.get(
                        `/api/MagazineCatalog/UnitExist/${item.capacityUnitName}`
                    );
                    if (capacityUnitData != null) {
                        this.mappedCapacityUnitId = capacityUnitData;
                    }
                }
            },
        },
    };
</script>

<style scoped>
    .disabled {
        opacity: 50%;
    }
</style>
