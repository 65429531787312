import { camelToKebabCase } from "@/services/stringUtility";

const itemView = () => import("@/features/schemas/views/SchemaItemView.vue");
const tableView = () => import("@/features/schemas/views/SchemaTableView.vue");

// TODO: work out how to do this dynamically at runtime.
// Not sure how to do a load step before the router is initialized.
const entities = [
    { entityKey: "actionLog", plural: "actionLogs" },
    { entityKey: "notificationLog", plural: "notificationLogs" },
    { entityKey: "approvedPerson", plural: "approvedPeople" },
    { entityKey: "contact", plural: "contacts" },
    { entityKey: "icon", plural: "icons" },
    { entityKey: "magazineArea", plural: "magazineAreas" },
    { entityKey: "magazineKey", plural: "magazineKeys" },
    { entityKey: "magazineKeyHistory", plural: "magazineKeyHistories" },
    { entityKey: "magazineAreaItemType", plural: "magazineAreaItemTypes" },
    { entityKey: "magazineAreaTransit", plural: "magazineAreaTransits" },
    { entityKey: "magazineCapacity", plural: "magazineCapacities" },
    { entityKey: "magazineDocumentType", plural: "magazineDocumentTypes" },
    { entityKey: "magazineItem", plural: "magazineItems" },
    { entityKey: "magazineItemCache", plural: "magazineItemCaches" },
    { entityKey: "magazineItemCategory", plural: "magazineItemCategories" },
    { entityKey: "magazineItemCategoryFile", plural: "magazineItemCategoryFiles" },
    { entityKey: "magazineItemFile", plural: "magazineItemFiles" },
    { entityKey: "magazineItemManufacturer", plural: "magazineItemManufacturers" },
    { entityKey: "magazineItemMovement", plural: "magazineItemMovements" },
    { entityKey: "magazineItemMovementBatch", plural: "magazineItemMovementBatches" },
    { entityKey: "magazineItemType", plural: "magazineItemTypes" },
    { entityKey: "magazineStocktake", plural: "magazineStocktakes" },
    { entityKey: "magazineStocktakeBatch", plural: "magazineStocktakeBatches" },
    { entityKey: "magazineMovementStandard", plural: "magazineMovementStandards" },
    { entityKey: "magazineMovementStandardItem", plural: "magazineMovementStandardItems" },
    { entityKey: "feedbackItem", plural: "feedbackItems" },
    { entityKey: "location", plural: "locations", helpLink: "locations" },
    { entityKey: "locationGroup", plural: "locationGroups" },
    { entityKey: "uploadError", plural: "uploadErrors" },
    { entityKey: "operatorShiftTime", plural: "operatorShiftTimes" },
    { entityKey: "operatorShift", plural: "operatorShifts" },
    { entityKey: "itemOrder", plural: "itemOrders" },
    { entityKey: "itemOrderBatch", plural: "itemOrderBatches" },
    { entityKey: "relativeLevel", plural: "relativeLevels" },
    { entityKey: "developmentDrillPlanType", plural: "developmentDrillPlanTypes" },
    { entityKey: "productionDrillHoleSize", plural: "productionDrillHoleSizes" },
    { entityKey: "inspectionAnswer", plural: "inspectionAnswers" },
    { entityKey: "inspectionCategory", plural: "inspectionCategories" },
    { entityKey: "inspectionItem", plural: "inspectionItems" },
    { entityKey: "workplaceInspection", plural: "workplaceInspections" },
    { entityKey: "workplaceInspectionApproval", plural: "workplaceInspectionApprovals" },
    { entityKey: "userNotification", plural: "userNotifications" },
    { entityKey: "hazard", plural: "hazards" },
    { entityKey: "chargeStandard", plural: "chargeStandards" },
    { entityKey: "chargeStandardItem", plural: "chargeStandardItems" },
    { entityKey: "operatorLog", plural: "operatorLogs" },
    { entityKey: "diamondDrillGroundStatusType", plural: "diamondDrillGroundStatusTypes" },
    { entityKey: "exmStocktakeAttemptItem", plural: "exmStocktakeAttemptItems" },
    { entityKey: "exmAdjustment", plural: "exmAdjustments" },
    { entityKey: "fragmentationInfo", plural: "fragmentationInfos" },
    { entityKey: "migration", plural: "migrations" },
];

const itemRoutes = entities.map((e) => {
    let itemPath = `${camelToKebabCase(e.entityKey)}-2`;
    return {
        path: `/${itemPath}/:itemId`,
        name: itemPath,
        staticProps: { entityKey: e.entityKey },
        meta: e.helpLink != null ? { helpLink: e.helpLink } : {},
        component: itemView,
    };
});

const tableRoutes = entities.map((e) => {
    let tablePath = `${camelToKebabCase(e.plural)}-2`;
    return {
        path: `/${tablePath}`,
        name: tablePath,
        staticProps: { entityKey: e.entityKey },
        meta: e.helpLink != null ? { helpLink: e.helpLink } : {},
        component: tableView,
    };
});

export function getRoutes() {
    return [...itemRoutes, ...tableRoutes];
}

export function getTableRoute(entityKey) {
    const matchingRoutes = tableRoutes.filter((r) => r.staticProps?.entityKey == entityKey);
    return matchingRoutes.length ? matchingRoutes[0] : null;
}

export function getItemRoute(entityKey) {
    const matchingRoutes = itemRoutes.filter((r) => r.staticProps?.entityKey == entityKey);
    return matchingRoutes.length ? matchingRoutes[0] : null;
}

export function hasItemPage(entityKey) {
    return entities.some((e) => e.entityKey == entityKey);
}
