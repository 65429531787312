<template>
    <div class="catalog-selection-container d-flex flex-wrap">
        <div v-for="item in filteredItems" :key="item.id" class="pa-1 col-md-4 col-lg-3 col-sm-6">
            <magazine-item-card :magazine-item="item" :is-category="isCategory" @click="onSelectItem(item)" />
        </div>

        <div v-if="filteredItems.length === 0" class="empty-state">No items found</div>
    </div>
</template>

<script>
    import axios from "axios";
    import { get } from "@/features/schemas/services/schemaApi";
    import MagazineItemCard from "./MagazineItemCard.vue";

    export default {
        components: {
            MagazineItemCard,
        },
        props: {
            value: Object,
            filter: {
                type: Object,
                default: () => ({
                    manufacturer: null,
                    magazineItemTypeId: null,
                }),
            },
            isCategory: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                items: [],
            };
        },
        async mounted() {
            await this.loadItems();
        },
        methods: {
            async loadItems() {
                const params = {};

                if (this.filter.manufacturer) {
                    params.manufacturer = this.filter.manufacturer.name;
                }

                if (this.filter.magazineItemTypeId) {
                    const magazineItemType = await get("magazineItemType", this.filter.magazineItemTypeId);
                    params.type = magazineItemType.magazineType;
                }

                const endpoint = this.isCategory ? "Categories" : "Items";
                const { data } = await axios.get(`/api/MagazineCatalog/${endpoint}`, { params });
                this.items = data;
            },
            onSelectItem(item) {
                // Dereference the item object before emitting it to the parent component, so the catalog item data doesn't change.
                const data = JSON.parse(JSON.stringify(item));
                if (data.magazineInventoryItems?.length > 0) {
                    data.magazineInventoryItems = data.magazineInventoryItems.map((x) => {
                        return {
                            ...x,
                            icon: item.icon,
                        };
                    });
                }

                this.$emit("input", data);
            },
        },
        computed: {
            filteredItems() {
                return this.items.filter((item) => {
                    if (this.filter.search) {
                        return item.name.toLowerCase().includes(this.filter.search.toLowerCase());
                    }
                    return true;
                });
            },
        },
        watch: {
            filter: {
                deep: true,
                handler() {
                    this.loadItems();
                },
            },
        },
    };
</script>

<style scoped>
    .catalog-selection-container {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 8px 0px;
    }

    .empty-state {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 0.38);
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
