<template>
    <v-row dense class="px-1">
        <v-col>
            <v-autocomplete
                v-model="filter.manufacturer"
                :value="manufacturer"
                :items="manufacturers"
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                label="Manufacturer"
                return-object
                @input="updateFilter"
            />
        </v-col>
        <v-col>
            <schema-search-select
                v-model="filter.magazineItemTypeId"
                entity-key="magazineItemType"
                label="Item Type"
                search-when-blank
                hide-details
                clearable
                @input="updateFilter"
            />
        </v-col>

        <v-col>
            <app-text-field v-model="filter.search" label="Search" hide-details clearable @input="updateFilter" />
        </v-col>
    </v-row>
</template>

<script>
    import axios from "axios";

    export default {
        name: "CatalogFilter",
        props: {
            value: {
                type: Object,
                required: true,
            },
            manufacturer: {
                type: Object,
                default: null,
            },
            isCategory: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                manufacturers: [],
                filter: {
                    manufacturer: null,
                    magazineItemTypeId: null,
                    search: "",
                },
            };
        },
        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.filter = { ...newValue };
                },
            },
        },
        async mounted() {
            await this.loadManufacturers();
        },
        methods: {
            async loadManufacturers() {
                const { data: manufacturers } = await axios.get("/api/MagazineCatalog/Manufacturers", {
                    params: {
                        isCategory: this.isCategory,
                    },
                });
                this.manufacturers = manufacturers;
            },
            updateFilter() {
                this.$emit("input", this.filter);
            },
        },
    };
</script>
