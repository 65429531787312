<template>
    <div class="manufacturer-container d-flex flex-column flex-shrink-0">
        <v-card class="manufacturer-card">
            <v-card-title class="v-center">
                <img
                    width="75%"
                    :src="require(`@/assets/${toCamelCase(manufacturer.name)}/${manufacturer.logo}.png`)"
                />
            </v-card-title>
            <v-card-text class="description text-subtitle-2">
                {{ manufacturer.description }}
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { toCamelCase } from "@/services/stringUtility";
    export default {
        props: {
            manufacturer: {
                type: Object,
                required: true,
            },
        },
        methods: {
            toCamelCase,
        },
    };
</script>

<style lang="scss" scoped>
    .manufacturer-container {
        width: 300px;
        display: grid;
        column-gap: 8px;
        row-gap: 8px;
    }

    .manufacturer-card {
        width: 300px;
        height: fit-content;
        background: white;

        > .description {
            white-space: break-spaces;
            text-align: left;
        }
    }
</style>
