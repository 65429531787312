<template>
    <div class="item-container">
        <div class="icon-container">
            <exm-icon v-if="icon" :icon="icon" />
        </div>
        <div class="content">
            <div class="title">{{ name }}</div>
            <div class="subtitle">{{ description }}</div>
        </div>
    </div>
</template>

<script>
    import ExmIcon from "@/components/ExmIcon.vue";
    export default {
        name: "ItemContainer",
        components: {
            ExmIcon,
        },
        props: {
            icon: {
                type: Object,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/colors.scss";

    .item-container {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .icon-container {
        background: rgba($color-1, 0.1);
        border-radius: 8px;
        border: 1px solid rgba($color-1, 0.1);
        width: 360px;
        height: 360px;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        :deep(exm-icon) {
            height: 256px;
            width: 256px;
        }
    }

    .content {
        padding-top: 1rem;
        text-align: center;
    }

    .title {
        font-size: 2.125rem;
        font-weight: 700;
    }

    .subtitle {
        font-size: 1.25rem;
        font-weight: 500;
    }
</style>
