<template>
    <div class="d-flex flex-nowrap fill-height overflow-auto pa-4">
        <item-container :icon="value.icon" :name="value.name" :description="value.description" />

        <v-row no-gutters>
            <v-col cols="12" class="text-center align-self-center pa-0">
                <v-alert color="primary" outlined>
                    The
                    <v-icon color="primary">mdi-sync</v-icon>
                    icon indicates that the column is synced. When you update a synced column, all other items will be
                    updated with the same value.
                    <br />
                    Click the icon to enable or disable syncing.
                </v-alert>
            </v-col>
            <v-col cols="12" class="data-table-container flex-grow-1 flex-shrink-0">
                <v-data-table
                    :headers="computedHeaders"
                    :items="localItems"
                    hide-default-footer
                    :footer-props="{ 'items-per-page-options': [-1] }"
                    :sort-by.sync="localSortBy"
                    :sort-desc.sync="localSortDesc"
                >
                    <template v-for="header in headers" v-slot:[`header.${header.value}`]>
                        <span :key="`${header.value}-text`" class="text-truncate">{{ header.text }}</span>

                        <v-btn
                            v-if="header.syncable"
                            :key="`${header.value}-sync`"
                            icon
                            class="mx-2"
                            :color="syncEnabled[header.value] ? 'primary' : ''"
                            @click.stop.prevent="toggleSync(header.value)"
                        >
                            <v-icon small>mdi-sync</v-icon>
                        </v-btn>
                    </template>

                    <template #item.icon="{ item }">
                        <v-menu v-if="item.icon" offset-x center :close-on-content-click="false">
                            <template #activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="isCustom ? on : null" class="d-flex">
                                    <exm-icon
                                        style="height: 36px; width: 36px"
                                        :icon="{
                                            iconImage: 'detClip',
                                            primaryColor: null,
                                            secondaryColor: null,
                                            backgroundColor: item.icon.backgroundColor,
                                        }"
                                    />
                                </div>
                            </template>
                            <v-color-picker
                                show-swatches
                                swatches-max-height="300px"
                                mode="hexa"
                                v-model="item.icon.backgroundColor"
                                flat
                            />
                        </v-menu>
                    </template>

                    <template v-for="header in editableHeaders" v-slot:[`item.${header.value}`]="{ item }">
                        <app-text-field
                            :key="header.value"
                            v-model="item[header.value]"
                            :type="header.type"
                            :rules="header.rules"
                            hide-details
                            @input="updateItem(item, header.value)"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ExmIcon from "@/components/ExmIcon.vue";
    import AppTextField from "@/components/AppTextField.vue";
    import ItemContainer from "./ItemContainer.vue";

    import { getCatalogHeaders } from "./catalogHelpers";

    export default {
        name: "CatalogCategorySetup",
        components: { ItemContainer, ExmIcon, AppTextField },
        props: {
            value: {
                type: Object,
                required: true,
            },
            items: {
                type: Array,
                default: () => [],
            },
            sortBy: {
                type: String,
                default: "priority",
            },
            sortDesc: {
                type: Boolean,
                default: false,
            },
            showIcon: {
                type: Boolean,
                default: true,
            },
            isCustom: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                localSortBy: this.sortBy,
                localSortDesc: this.sortDesc,
                syncEnabled: {
                    quantityPerBox: false,
                    quantityPerPallet: false,
                    weightPerItem: true,
                },
            };
        },
        computed: {
            localItems: {
                get() {
                    return JSON.parse(JSON.stringify(this.items));
                },
                set(newItems) {
                    this.$emit("update:items", newItems);
                },
            },
            headers() {
                return getCatalogHeaders(this.showIcon);
            },
            computedHeaders() {
                return this.headers.filter((x) => x.visible === undefined || x.visible());
            },
            editableHeaders() {
                return this.headers.filter((header) => header.editable);
            },
        },
        methods: {
            toggleSync(headerValue) {
                if (this.syncEnabled[headerValue] !== undefined) {
                    this.syncEnabled[headerValue] = !this.syncEnabled[headerValue];
                }
            },
            updateItem(item, headerValue) {
                const items = [...this.localItems];

                // If sync is enabled, update all items
                if (this.syncEnabled[headerValue]) {
                    const newValue = item[headerValue];
                    for (const tableItem of items) {
                        tableItem[headerValue] = newValue;
                    }
                } else {
                    const index = items.findIndex((i) => i === item);
                    if (index !== -1) {
                        items[index] = { ...item };
                    }
                }

                this.localItems = items;
            },
        },
        watch: {
            sortBy: {
                immediate: true,
                handler(newVal) {
                    this.localSortBy = newVal;
                },
            },
            sortDesc: {
                immediate: true,
                handler(newVal) {
                    this.localSortDesc = newVal;
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    .data-table-container {
        max-height: 500px;
        overflow: auto;
    }
</style>
