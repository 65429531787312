<template>
    <div>
        <app-text-field v-model="value.name" label="Name" :rules="[(v) => !!v || 'is required']" />
        <app-text-field v-model="value.description" label="Description" />
        <form-search-select v-model="value.manufacturer" :field="manufacturerField" />
        <form-search-select v-model="value.magazineItemTypeId" :field="magazineItemTypeField" />
        <form-search-select v-model="value.mappedUnitId" :field="unitField" />
        <form-search-select v-model="value.mappedCapacityUnitId" :field="capacityUnitField" />
    </div>
</template>

<script>
    import FormSearchSelect from "@/features/schemas/formFields/FormSearchSelect.vue";

    export default {
        components: { FormSearchSelect },
        props: {
            value: Object,
        },
        data() {
            return {
                manufacturerField: {
                    dependsOn: {
                        type: "magazineItemManufacturer",
                    },
                    required: {},
                    type: "guid?",
                    title: "Manufacturer",
                },
                magazineItemTypeField: {
                    dependsOn: {
                        type: "magazineItemType",
                    },
                    required: {},
                    type: "guid?",
                    title: "Magazine Item Type",
                },
                unitField: {
                    dependsOn: {
                        type: "unit",
                    },
                    required: {},
                    type: "guid?",
                    title: "Unit",
                },
                capacityUnitField: {
                    dependsOn: {
                        type: "unit",
                    },
                    type: "guid?",
                    title: "Capacity Unit",
                },
            };
        },
    };
</script>
