<template>
    <div class="d-flex flex-nowrap fill-height overflow-hidden">
        <item-container :icon="value.icon" :name="value.title" :description="value.subtitle" />

        <div class="flex-grow-0 flex-shrink-1 pa-4" style="overflow-y: auto; overflow-x: hidden">
            <v-row no-gutters>
                <v-col cols="12" v-for="header in headers" :key="header.value">
                    <div>
                        <span class="text-subtitle-1 font-weight-medium">{{ header.text }}</span>
                        <app-text-field
                            v-model="item[header.value]"
                            :placeholder="header.text"
                            :rules="header.rules"
                            :type="header.type"
                            hide-details
                        />
                    </div>
                </v-col>

                <v-col cols="12" class="pt-2">
                    <unit-mapping v-model="item" :item="value" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import AppTextField from "@/components/AppTextField.vue";
    import ItemContainer from "./ItemContainer.vue";
    import UnitMapping from "./UnitMapping.vue";

    import { getCatalogHeaders, getDefaultItem } from "./catalogHelpers";

    export default {
        name: "CatalogItemSetup",
        components: { ItemContainer, AppTextField, UnitMapping },
        props: {
            value: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                headers: getCatalogHeaders().filter((x) => x.value != "icon"),
                item: getDefaultItem(),
            };
        },

        watch: {
            value: {
                immediate: true,
                handler(value) {
                    if (!value) return;

                    for (const header of this.headers) {
                        this.item[header.value] = value[header.value];
                    }
                },
            },
            item: {
                deep: true,
                handler(newValue) {
                    this.$emit("input", {
                        ...this.value,
                        name: newValue.name,
                        description: newValue.description,
                        weightPerItem: newValue.weightPerItem,
                        quantityPerBox: newValue.quantityPerBox,
                        quantityPerPallet: newValue.quantityPerPallet,
                        priority: newValue.priority,
                        capacityUnitId: newValue.mappedCapacityUnitId,
                        unitId: newValue.mappedUnitId,
                    });
                },
            },
        },
    };
</script>
