<template>
    <div class="tds-container d-flex flex-column flex-shrink-0">
        <v-card v-if="manufacturerGroup" class="tds-card">
            <v-card-title class="v-center">
                <img
                    width="75%"
                    :src="
                        require(`@/assets/${toCamelCase(manufacturerGroup.manufacturer)}/${manufacturerGroup.logo}.png`)
                    "
                />
            </v-card-title>
            <v-card-text class="description text-subtitle-2">
                {{ manufacturerGroup.description }}
            </v-card-text>
            <v-card-actions>
                <a :href="tds" download style="width: 100%">
                    <app-button style="width: 100%">Download TDS</app-button>
                </a>
            </v-card-actions>
        </v-card>
        <v-card class="tds-card" v-else>
            <v-card-title class="v-center">
                <p>No manufacturer information available.</p>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
    import { toCamelCase } from "@/services/stringUtility";
    export default {
        props: {
            tds: {
                type: String,
                required: true,
            },
            manufacturerGroup: {
                type: Object,
            },
        },
        methods: {
            toCamelCase,
        },
    };
</script>

<style lang="scss" scoped>
    .tds-container {
        width: 300px;
        display: grid;
        column-gap: 8px;
        row-gap: 8px;
    }

    .tds-card {
        width: 300px;
        height: fit-content;
        background: white;

        > .description {
            white-space: break-spaces;
            text-align: left;
        }
    }
</style>
