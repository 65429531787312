import store from "../store/store";
import { isNullOrWhiteSpace } from "./stringUtility";

function getErrorMessages(e) {
    var messages = [];

    if (hasResponse(e)) {
        var data = e.response.data;

        if (e.response?.status === 403) {
            // Don't want to display this error to the user as it's a claims issue (most likely).
            return [];
        }

        if (typeof data === "string" && !isNullOrWhiteSpace(data)) {
            messages.push(data);
        }
        if (data.title) {
            messages.push(data.title);
        }
        if (data.name) {
            messages.push(data.name);
        }

        if (data.message) {
            messages.push(data.message);
        } else if (data.Message) {
            messages.push(data.Message);
        }

        if (data.errors) {
            for (var key in data.errors) {
                var errorsForKey = data.errors[key];

                for (var i = 0; i < errorsForKey.length; i++) {
                    messages.push(errorsForKey[i]);
                }
            }
        }
        //} else {
        //    if (messages.length === 0) {
        //        messages.push(e.message);
        //    }
        //}
    } else if (typeof e === "string") {
        messages.push(e);
    } else if (e.message) {
        messages.push(e.message);
    } else {
        var stringified = JSON.stringify(e);
        if (stringified !== "{}") {
            messages.push(stringified);
        } else {
            messages.push(e);
        }
    }

    if (messages.length == 0) {
        if (e.hasOwnProperty("message")) {
            messages.push(e.message);
        } else {
            messages.push("An error has occurred");
        }
    }

    return messages;
}

export function getErrorMessage(e) {
    if (typeof e === "string") {
        return e;
    }
    return getErrorMessages(e).join("\n");
}

function hasResponse(e) {
    return e.hasOwnProperty("response") && e.response && e.response.hasOwnProperty("data");
}

export function handleError(e) {
    // eslint-disable-next-line no-console
    console.error(e);
    store.dispatch("alert/error", getErrorMessage(e));
}

// TODO: remove this once all references to it are gone.
function handleServerError(e) {
    handleError(e);
}

export default {
    getErrorMessage,
    getErrorMessages,
    handleServerError,
    handleError,
};
