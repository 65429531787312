const decimalRules = (field) => [
    (v) => !v || (!isNaN(parseInt(v)) && parseInt(v) > -1) || `${field} must be a positive number`,
    (v) => !v || /^\d*\.?\d*$/.test(v) || `${field} must be a number or decimal`,
];

export const getCatalogHeaders = (showIcon = true) => {
    const headers = [
        {
            text: "Icon",
            value: "icon",
            sortable: false,
            align: "center",
            visible: () => showIcon,
            editable: false,
        },
        {
            text: "Name",
            value: "name",
            type: "text",
            rules: [(v) => !!v || "Name is required"],
            editable: true,
        },
        {
            text: "Description",
            value: "description",
            type: "text",
            rules: [],
            editable: true,
        },
        {
            text: "Quantity Per Box",
            value: "quantityPerBox",
            type: "number",
            rules: decimalRules("Quantity Per Box"),
            editable: true,
            syncable: true,
        },
        {
            text: "Quantity Per Pallet",
            value: "quantityPerPallet",
            type: "number",
            rules: decimalRules("Quantity Per Pallet"),
            editable: true,
            syncable: true,
        },
        {
            text: "Weight Per Item",
            value: "weightPerItem",
            type: "number",
            rules: decimalRules("Weight Per Item"),
            editable: true,
            syncable: true,
        },
        {
            text: "Priority",
            value: "priority",
            type: "number",
            rules: [
                (v) => (v !== null && v !== "") || "Priority is required",
                (v) => !v || !isNaN(parseInt(v)) || "Priority must be a number",
            ],
            editable: true,
        },
    ];

    return headers;
};

// Default empty item structure
export const getDefaultItem = () => ({
    name: "",
    description: null,
    weightPerItem: null,
    quantityPerBox: null,
    quantityPerPallet: null,
    priority: 0,
    mappedUnitId: null,
    mappedCapacityUnitId: null,
});
