<template>
    <div class="py-4">
        <v-form ref="form" class="px-8" @submit.prevent="addCategoryItem">
            <p class="text-caption pb-2">The inputs below allow you to populate the defaults for new category items.</p>

            <v-row v-for="(row, index) in formFields" :key="index">
                <v-col v-for="field in row" :key="field.key" :cols="field.cols">
                    <app-text-field
                        v-model.number="formData[field.key]"
                        :label="field.label"
                        :type="field.type"
                        :rules="field.rules"
                    />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col align-self="center">
                    <v-checkbox v-model="computedShowIcon" label="Show Det Clips" hide-details class="my-0 py-0" />
                </v-col>
                <v-col align="center">
                    <app-button @click="addCategoryItem">Add Category Item</app-button>
                </v-col>
                <v-col />
            </v-row>
        </v-form>

        <catalog-category-setup
            v-model="customSelection"
            :items.sync="localValue"
            :show-icon="showIcon"
            is-custom
            sort-by="priority"
            :sort-desc="true"
        />
    </div>
</template>

<script>
    import { getCatalogHeaders } from "./catalogHelpers";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";
    import CatalogCategorySetup from "./CatalogCategorySetup.vue";

    export default {
        components: { CatalogCategorySetup },

        props: {
            value: Array,
            customSelection: Object,
            showIcon: Boolean,
        },

        data() {
            return {
                formData: {
                    description: null,
                    quantityPerBox: null,
                    quantityPerPallet: null,
                    weightPerItem: null,
                },
                isCatalogValid: true,
                headers: getCatalogHeaders(this.showIcon),
            };
        },

        computed: {
            formFields() {
                const rules = {
                    required: [(v) => !!v || "is required"],
                    positiveNumber: [(v) => v > 0 || isNullOrWhiteSpace(v) || "must be greater than 0"],
                };

                return [
                    [
                        {
                            key: "description",
                            label: "Item Description",
                            type: "text",
                            cols: 8,
                        },
                    ],
                    [
                        {
                            key: "quantityPerBox",
                            label: "Quantity Per Box",
                            type: "number",
                            cols: 4,
                            rules: rules.positiveNumber,
                        },
                        {
                            key: "quantityPerPallet",
                            label: "Quantity Per Pallet",
                            type: "number",
                            cols: 4,
                            rules: rules.positiveNumber,
                        },
                        {
                            key: "weightPerItem",
                            label: "Weight Per Item",
                            type: "number",
                            cols: 4,
                            rules: rules.positiveNumber,
                        },
                    ],
                ];
            },
            computedHeaders() {
                return this.headers.filter((x) => x.visible === undefined || x.visible());
            },
            localValue: {
                get() {
                    return this.value;
                },
                set(newVal) {
                    this.$emit("input", newVal);
                },
            },
            computedShowIcon: {
                get() {
                    return this.showIcon;
                },
                set(value) {
                    this.$emit("update:showIcon", value);
                },
            },
        },

        methods: {
            isNullOrWhiteSpace,
            addCategoryItem() {
                if (!this.$refs.form.validate()) {
                    return;
                }

                const numElements = this.localValue.length;
                const lastBackgroundColor =
                    numElements < 1 ? "#000000" : this.localValue[numElements - 1].backgroundColor;

                const inventoryItem = {
                    id: numElements,
                    name: `${numElements}`,
                    description: this.formData.description,
                    backgroundColor: lastBackgroundColor,
                    weightPerItem: this.formData.weightPerItem,
                    quantityPerBox: this.formData.quantityPerBox,
                    quantityPerPallet: this.formData.quantityPerPallet,
                    priority: 0,
                };
                this.localValue.push(inventoryItem);
            },

            deleteItem(index) {
                this.localValue.splice(index, 1);
            },
        },

        watch: {
            categoryName: {
                handler() {
                    this.formData.description = this.categoryName;
                },
            },
        },
    };
</script>

<style scoped>
    ::v-deep .v-small-dialog__activator__content {
        display: flex;
    }

    ::v-deep .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
    }

    ::v-deep .data-table-container {
        max-height: 300px;
    }
</style>
