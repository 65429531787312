<template>
    <v-card class="magazine-item-card" v-on="$listeners">
        <v-card-title class="py-2 pb-0 text-ellipsis overflow-hidden text-no-wrap">
            {{ isCategory ? magazineItem.name : magazineItem.title }}
        </v-card-title>
        <v-card-subtitle class="py-2 font-weight-medium text-ellipsis overflow-hidden text-no-wrap">
            {{ isCategory ? magazineItem.description : magazineItem.subtitle }}
        </v-card-subtitle>
        <div class="content py-2">
            <exm-icon :icon="magazineItem.icon" width="64px" height="64px" />
            <img v-if="manufacturerIcon" :src="manufacturerIcon" class="manufacturer-icon" />
        </div>
        <div class="footer py-2">
            <img v-if="manufacturerGroupIcon" :src="manufacturerGroupIcon" width="50%" height="50%" />
        </div>
    </v-card>
</template>

<script>
    import { defineComponent } from "vue";
    import { toCamelCase } from "@/services/stringUtility";
    import ExmIcon from "@/components/ExmIcon.vue";

    export default defineComponent({
        name: "MagazineItemCard",

        components: {
            ExmIcon,
        },

        props: {
            magazineItem: {
                type: Object,
                required: true,
            },
            isCategory: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            manufacturerGroupIcon() {
                let manufacturerName = this.magazineItem.manufacturerName;
                let manufacturerGroupLogo = this.magazineItem.manufacturerGroupLogo;
                if (this.isCategory && this.magazineItem.magazineInventoryItems.length > 0) {
                    manufacturerName = this.magazineItem.magazineInventoryItems[0].manufacturerName;
                    manufacturerGroupLogo = this.magazineItem.magazineInventoryItems[0].manufacturerGroupLogo;
                }
                if (manufacturerGroupLogo === null) return null;

                const manufacturer = toCamelCase(manufacturerName);
                //TODO: One type, eg; SVG or PNG currently have placeholder png assets
                return require(`@/assets/${manufacturer}/${manufacturerGroupLogo}.png`);
            },
            manufacturerIcon() {
                if (!this.magazineItem.manufacturer?.icon) return null;
                const manufacturer = toCamelCase(this.magazineItem.manufacturer.name);
                return require(`@/assets/${manufacturer}/${this.magazineItem.manufacturer.icon}`);
            },
        },
    });
</script>

<style lang="scss" scoped>
    @import "@/assets/colors.scss";

    .magazine-item-card {
        color: $color-1;
        border: 1px solid rgba($color-1, 0.5);
        border-radius: 8px;
        min-height: 250px;
        max-height: 250px;
        height: 250px;

        > .v-card__title {
            height: 48px;
        }

        > .v-card__subtitle {
            height: 32px;
        }

        > .content,
        .footer {
            max-height: 75px;
            min-height: 75px;
            padding: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        > .content {
            position: relative;
            border: 1px solid rgba($color-1, 0.1);
            background: rgba($color-1, 0.1);
            margin: 0 8px;
            border-radius: 8px;
            height: 100px;
            max-height: 100px;
            min-height: 100px;
        }

        > .footer > img {
            object-fit: cover;
        }

        .description {
            text-align: center;
            font-size: 0.875rem;
            color: rgba($color-1, 0.8);
            margin: 0;
        }

        .manufacturer-icon {
            position: absolute;
            width: 20%;
            bottom: 8px;
            right: 8px;
        }
    }
</style>
