import licenseService from "../services/licenseService";

export const license = {
    namespaced: true,
    state: {
        license: {},
    },
    actions: {
        async loadLicense({ commit }) {
            try {
                const license = await licenseService.loadLicense();
                commit("license", license);
            } catch {
                commit("license", null);
            }
        },
    },
    mutations: {
        license(state, license) {
            state.license = license;
        },
    },
};
