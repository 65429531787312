import axios from "axios";

export default {
    loadLicense,
};

// Poll for the license information
async function loadLicense() {
    const { data } = await axios.get("/api/License/Info");
    return data;
}
