<template>
    <app-dialog :value="value" :max-width="showManufacturer || showTds ? '100%' : '80%'" style="box-shadow: none">
        <v-form ref="form" v-model="isValid">
            <div class="d-flex flex-nowrap">
                <!-- Manufacturer -->
                <manufacturer-card v-if="showManufacturer" :manufacturer="selectedManufacturer" />
                <tds-card v-if="showTds" :tds="selectedTds" :manufacturer-group="selectedManufacturerGroup" />
                <!-- Wizard -->
                <v-card
                    class="dialog-card"
                    :class="{
                        'mx-2': showManufacturer || showTds,
                    }"
                >
                    <v-card-title class="pb-0">
                        <v-stepper v-model="step" style="width: 100%" class="elevation-0">
                            <v-stepper-header style="overflow: hidden">
                                <template v-for="(label, index) in stepLabels">
                                    <v-stepper-step :key="index" :complete="step > index + 1" :step="index + 1">
                                        {{ label }}
                                    </v-stepper-step>
                                    <v-divider v-if="index < stepLabels.length - 1" :key="`divider-${index}`" />
                                </template>
                            </v-stepper-header>
                        </v-stepper>
                    </v-card-title>
                    <v-card-text>
                        <v-stepper v-model="step">
                            <v-stepper-items>
                                <v-stepper-content
                                    v-for="(content, index) in stepContents"
                                    :key="index"
                                    :step="index + 1"
                                    class="py-0 px-4"
                                >
                                    <template v-if="step === index + 1">
                                        <v-alert
                                            v-if="isFinalStep && !isValid"
                                            type="error"
                                            dense
                                            class="v-center ma-0"
                                        >
                                            Please validate all fields and fix any errors before saving.
                                        </v-alert>
                                        <template v-if="content === 'item-selection'">
                                            <v-tabs v-model="tab" fixed-tabs @change="onTabChange">
                                                <v-tab>Catalog</v-tab>
                                                <v-tab>Custom</v-tab>
                                            </v-tabs>
                                            <v-tabs-items v-model="tab" class="pt-4">
                                                <v-tab-item>
                                                    <catalog-filter
                                                        v-model="catalogFilter"
                                                        :is-category="isCategoryWizard"
                                                    />
                                                    <catalog-selection
                                                        v-model="catalogSelection"
                                                        :filter="catalogFilter"
                                                        :is-category="isCategoryWizard"
                                                        @input="submitForm"
                                                    />
                                                </v-tab-item>
                                                <v-tab-item>
                                                    <custom-selection
                                                        v-if="isCategoryWizard"
                                                        v-model="customSelection"
                                                    />
                                                    <magazine-item-form
                                                        ref="itemForm"
                                                        v-else
                                                        v-model="item"
                                                        :fixed-values="{
                                                            catalogTitle: null,
                                                            catalogSubtitle: null,
                                                            magazineItemManufacturerGroupId: null,
                                                        }"
                                                        entity-key="magazineItem"
                                                    >
                                                        <template #formTitle><div></div></template>
                                                        <template #formActions><div></div></template>
                                                    </magazine-item-form>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </template>
                                        <template v-else-if="content === 'category-icon'">
                                            <category-icon
                                                v-if="isCustom"
                                                v-model="customSelection.icon"
                                                class="pt-4"
                                            />
                                        </template>
                                        <template v-else-if="content === 'unit-mapping'">
                                            <unit-mapping
                                                v-if="catalogSelection"
                                                v-model="catalogSelection.magazineInventoryItems"
                                                :item="catalogSelection"
                                            />
                                        </template>
                                        <template v-else-if="content === 'custom-item-setup'">
                                            <custom-item-setup
                                                v-model="categoryItems"
                                                :custom-selection="customSelection"
                                                :show-icon="showIcon"
                                                @update:showIcon="updateShowIcon"
                                            />
                                        </template>
                                        <template v-else-if="content === 'finalise'">
                                            <catalog-item-setup
                                                v-if="catalogSelection && !isCustom && !isCategoryWizard"
                                                v-model="catalogSelection"
                                            />
                                            <catalog-category-setup
                                                v-if="catalogSelection && !isCustom && isCategoryWizard"
                                                v-model="catalogSelection"
                                                :items.sync="catalogSelection.magazineInventoryItems"
                                                :show-icon="showIcon"
                                                sort-by="priority"
                                                :sort-desc="true"
                                            />
                                        </template>
                                    </template>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>

                        <v-progress-linear v-if="isSaving" indeterminate color="primary" />
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <app-button color="primary" text @click="cancel">
                            {{ step === 1 ? "Cancel" : "Back" }}
                        </app-button>
                        <v-spacer />
                        <app-button
                            v-if="canShowNextButton"
                            text
                            :disabled="(isFinalStep && !isValid) || isSaving"
                            @click="submitForm"
                        >
                            {{ isFinalStep ? "Save" : "Next" }}
                        </app-button>
                    </v-card-actions>
                </v-card>
            </div>
        </v-form>
    </app-dialog>
</template>

<script>
    import axios from "axios";
    import CatalogCategorySetup from "./CatalogCategorySetup.vue";
    import CatalogFilter from "./CatalogFilter.vue";
    import CatalogItemSetup from "./CatalogItemSetup.vue";
    import CatalogSelection from "./CatalogSelection.vue";
    import CategoryIcon from "./CategoryIcon.vue";
    import CustomSelection from "./CustomSelection.vue";
    import CustomItemSetup from "./CustomItemSetup.vue";
    import MagazineItemForm from "@/features/schemas/forms/MagazineItemForm.vue";
    import ManufacturerCard from "./ManufacturerCard.vue";
    import TdsCard from "./TdsCard.vue";
    import UnitMapping from "./UnitMapping.vue";
    import errorUtility from "@/services/errorUtility";

    export default {
        components: {
            CatalogCategorySetup,
            CatalogFilter,
            CatalogItemSetup,
            CatalogSelection,
            CategoryIcon,
            CustomSelection,
            CustomItemSetup,
            MagazineItemForm,
            ManufacturerCard,
            UnitMapping,
            TdsCard,
        },
        props: {
            value: Boolean,
            isCategoryWizard: Boolean,
        },
        data() {
            return {
                wizard: false,
                step: 1,
                isValid: true,
                catalogFilter: {
                    manufacturer: null,
                    magazineItemTypeId: null,
                    search: null,
                },
                catalogSelection: null,
                customSelection: {
                    manufacturer: null,
                    magazineItemTypeId: null,
                    mappedUnitId: null,
                    mappedCapacityUnitId: null,
                    name: null,
                    description: null,
                    icon: {
                        iconImage: null,
                        primaryColor: null,
                        secondaryColor: null,
                        backgroundColor: null,
                    },
                },

                item: null,
                tab: null,
                categoryItems: [],
                showIcon: true,
                isSaving: false,
                manufacturerGroups: [],
            };
        },
        async mounted() {
            await this.getManufacturerGroups();
        },
        methods: {
            async getManufacturerGroups() {
                const { data } = await axios.get("/api/MagazineCatalog/ManufacturerGroups");
                this.manufacturerGroups = data;
            },
            onTabChange() {
                if (this.tab === 1) {
                    this.catalogFilter = {
                        manufacturer: null,
                        magazineItemTypeId: null,
                        search: null,
                    };
                }
            },
            cancel() {
                if (this.step > 1) {
                    this.step--;

                    if (this.step === 1) {
                        this.catalogSelection = null;
                    }

                    return;
                }
                this.$emit("close");
            },

            async submitForm() {
                const valid = await this.$refs.form.validate();

                if (!valid) return;

                if (this.isFinalStep) {
                    await this.save();
                    return;
                }
                this.step++;
            },

            async save() {
                if (this.isSaving) {
                    return;
                }

                this.isSaving = true;

                try {
                    if (!this.isCategoryWizard && this.isCustom) {
                        const itemForm = this.$refs.itemForm[0];
                        if (!(await itemForm.save())) {
                            this.isSaving = false;
                            return;
                        }
                        this.$emit("save", this.item.id);
                        return;
                    }

                    if (!this.isValid) {
                        this.isSaving = false;
                        return;
                    }

                    if (this.isCustom) {
                        await this.saveCategoryCustom();
                    } else if (!this.isCategoryWizard) {
                        await this.saveCatalogItem();
                    } else {
                        await this.saveCatalogCategory();
                    }
                } catch (error) {
                    errorUtility.handleError(error);
                } finally {
                    this.isSaving = false;
                }
            },

            async saveCatalogItem() {
                const item = this.catalogSelection;

                const selection = {
                    manufacturer: item.manufacturerName,
                    manufacturerGroup: item.manufacturerGroupName,
                    magazineItemType: item.magazineItemType,
                    unitId: item.unitId,
                    capacityUnitId: item.capacityUnitId || null,
                    name: item.name,
                    catalogTitle: item.title,
                    catalogSubtitle: item.subtitle,
                    description: item.description,
                    weightPerItem: item.weightPerItem,
                    quantityPerBox: item.quantityPerBox,
                    quantityPerPallet: item.quantityPerPallet,
                    priority: item.priority,
                };

                const { data } = await axios.post("/api/MagazineCatalog/SaveItem", selection);
                this.$emit("save", data);
            },

            async saveCatalogCategory() {
                const item = this.catalogSelection;

                const categorySelection = {
                    name: item.name,
                    description: item.description,
                    isShowIcon: this.showIcon,
                    priority: item.priority,
                    categoryItems: item.magazineInventoryItems.map((x) => ({
                        manufacturer: x.manufacturerName,
                        manufacturerGroup: x.manufacturerGroupName,
                        magazineItemType: x.magazineItemType,
                        unitId: x.mappedUnitId,
                        capacityUnitId: x.mappedCapacityUnitId || null,
                        name: x.name,
                        catalogTitle: x.title,
                        catalogSubtitle: x.subtitle,
                        description: x.description,
                        weightPerItem: x.weightPerItem,
                        quantityPerBox: x.quantityPerBox,
                        quantityPerPallet: x.quantityPerPallet,
                        priority: x.priority,
                    })),
                };

                const { data } = await axios.post("/api/MagazineCatalog/SaveCategory", categorySelection);
                this.$emit("save", data);
            },

            async saveCategoryCustom() {
                const customSelection = {
                    magazineItemManufacturerId: this.customSelection.manufacturer,
                    magazineItemTypeId: this.customSelection.magazineItemTypeId,
                    unitId: this.customSelection.mappedUnitId,
                    capacityUnitId: this.customSelection.mappedCapacityUnitId,
                    name: this.customSelection.name,
                    description: this.customSelection.description,
                    isShowIcon: this.showIcon,
                    categoryIcon: this.customSelection.icon,
                    categoryItems: this.categoryItems,
                };

                const { data } = await axios.post("/api/MagazineCatalog/SaveCustom", customSelection);
                this.$emit("save", data);
            },

            updateShowIcon(value) {
                this.showIcon = value;
            },
            getPropertyValue(selection, propertyPath) {
                if (!selection) return null;

                if (selection.magazineInventoryItems?.length > 0) {
                    return selection.magazineInventoryItems[0][propertyPath];
                }

                return selection[propertyPath];
            },
        },

        computed: {
            isCustom() {
                return this.tab === 1;
            },
            stepLabels() {
                if (this.isCustom && this.isCategoryWizard) return ["Create Category", "Category Icon", "Item Setup"];
                if (!this.isCustom && this.isCategoryWizard) return ["Select Item", "Unit Mapping", "Finalise"];
                if (this.isCustom && !this.isCategoryWizard) return ["Create Item"];
                return ["Item Selection", "Finalise"];
            },
            stepContents() {
                if (this.isCustom && this.isCategoryWizard)
                    return ["item-selection", "category-icon", "custom-item-setup"];
                if (!this.isCustom && this.isCategoryWizard) return ["item-selection", "unit-mapping", "finalise"];
                if (this.isCustom && !this.isCategoryWizard) return ["item-selection"];
                return ["item-selection", "finalise"];
            },
            isFinalStep() {
                return this.step === this.stepLabels.length;
            },
            canShowNextButton() {
                return this.step > 1 || this.isCustom;
            },
            showManufacturer() {
                const { manufacturer } = this.catalogFilter;
                return (
                    !this.isFinalStep &&
                    this.$vuetify.breakpoint.lgAndUp &&
                    manufacturer &&
                    manufacturer.logo &&
                    manufacturer.description
                );
            },
            showTds() {
                return this.$vuetify.breakpoint.lgAndUp && this.isFinalStep && this.selectedTds;
            },

            selectedTds() {
                return this.getPropertyValue(this.catalogSelection, "tds");
            },
            selectedManufacturerGroup() {
                if (!this.catalogSelection) return null;

                const manufacturerGroupName = this.getPropertyValue(this.catalogSelection, "manufacturerGroupName");

                return this.manufacturerGroups.find((x) => x.name === manufacturerGroupName);
            },

            selectedManufacturer() {
                return this.catalogFilter.manufacturer;
            },
        },
    };
</script>

<style lang="scss">
    .dialog-card {
        width: 100%;
    }

    .v-dialog {
        box-shadow: unset !important;
    }
    .v-sheet.v-stepper {
        box-shadow: unset !important;
    }
</style>
